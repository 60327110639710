const SharedDataReducer = (state, action) => {
  switch (action.type) {
    case "GET_SHARED_DATA_START":
      return {
        ...state,
        sharedDataFetchingState: {
          ...state.sharedDataFetchingState,
          isGetSharedDataFetching: true,
        },
        error: false,
      };
    case "GET_SHARED_DATA_SUCCESS":
      return {
        ...state,
        sharedData: action.payload.data,
        sharedDataFetchingState: {
          ...state.sharedDataFetchingState,
          isGetSharedDataFetching: false,
        },
        error: false,
      };
    case "GET_SHARED_DATA_FAILTURE":
      return {
        ...state,
        sharedDataFetchingState: {
          ...state.sharedDataFetchingState,
          isGetSharedDataFetching: false,
        },
        error: true,
      };
    default:
      return { ...state };
  }
};

export default SharedDataReducer;
