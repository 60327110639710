const AuthReducer = (state, action) => {
  switch (action.type) {
    case "AUTH_LOGIN_START":
      return {
        user: null,
        userDetails: null,
        authFetchingState: {
          ...state.authFetchingState,
          isAuthLoginFetching: true,
        },
        error: false,
      };
    case "AUTH_LOGIN_SUCCESS":
      return {
        ...state,
        user: action.payload,
        authFetchingState: {
          ...state.authFetchingState,
          isAuthLoginFetching: false,
        },
        error: false,
      };
    case "AUTH_LOGIN_USER_TYPE_SUCCESS":
      return {
        ...state,
        userData: action.payload,
        error: false,
      };
    case "AUTH_LOGIN_DETAILS_SUCCESS":
      return {
        ...state,
        userDetails: action.payload,
        error: false,
      };
    case "AUTH_LOGIN_FAILURE":
      return {
        user: null,
        userDetails: null,
        authFetchingState: {
          ...state.authFetchingState,
          isAuthLoginFetching: false,
        },
        error: true,
      };
    case "LOGOUT":
      return {
        user: null,
        userDetails: null,
      };
    default:
      return { ...state };
  }
};

export default AuthReducer;
